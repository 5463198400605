import React, { Fragment } from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/common/Layout/layout"
import SEO from "../components/common/SEO/seo"
import BannerPage from "../components/banner/BannerPage/bannerPage"
import SectionTextImage from "../components/section/TextImage/textImage"
import CallToAction from "../components/section/CallToAction/callToAction"

const AboutPage = () => {
    const data = useStaticQuery(graphql`
    query {
      aboutImage1: file(relativePath: { eq: "images/sections/quienes-somos/quienes-somos-ibxagency.png" }) {
        childImageSharp {
          fluid(maxWidth: 854) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      aboutImage2: file(relativePath: { eq: "images/sections/quienes-somos/especialistas-ibxagency.png" }) {
        childImageSharp {
          fluid(maxWidth: 854) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      aboutImage3: file(relativePath: { eq: "images/sections/quienes-somos/valor-ibxagency.png" }) {
        childImageSharp {
          fluid(maxWidth: 854) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      aboutImage4: file(relativePath: { eq: "images/sections/quienes-somos/por-que-confiar-ibxagency.png" }) {
        childImageSharp {
          fluid(maxWidth: 854) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

    return (
        <Layout>
            <SEO
                title="Quiénes somos"
                description="Agencia de Marketing Online especializada en la optimización en buscadores, posicionamiento web SEO, publicidad online SEM, analítica web, gestión de redes sociales para empresas, campañas de e-mailing y diseño de páginas web e e-commerce."
            />

            <BannerPage
                title="Sobre nosotros"
                text="Somos tu Agencia de Marketing Digital 360 en Barcelona"
                color="green"
            />

            <SectionTextImage
                title1="¿Quiénes"
                title2="somos?"
                text={<Fragment>
                    Somos una <strong>Agencia de Marketing Digital situada en Barcelona</strong> formada por un equipo dinámico propio de profesionales especializados en Marketing Digital. <br /><br />
                    Integrado por periodistas, comunicadores, publicistas, técnicos en marketing online, community managers, diseñadores, creativos e ingenieros informáticos. <strong>IBX es el engranaje ideal para hacer girar el motor de tu negocio digital</strong>.
                    </Fragment>}
                image={data.aboutImage1.childImageSharp.fluid}
                imageAlt="Quienes somos - Agencia Marketing Digital"
                color="green"
                containerTextRight={true}
            />

            <SectionTextImage
                title1="¿En qué somos "
                title2="especialistas?"
                text={<Fragment>
                    En IBX somos expertos <strong>en estrategias de marketing digital</strong>. Conocemos a la perfección el lenguaje de Internet, es por ello que desarrollamos planes integrales y globales de comunicación online adecuados a cada uno de nuestros clientes. Pensamos, ideamos, construimos, llevamos a cabo y analizamos cualquier reto y proyecto que se nos presente para que todos nuestros clientes tengan la mejor presencia online.
                </Fragment>}
                image={data.aboutImage2.childImageSharp.fluid}
                imageAlt="Especialistas - Agencia Marketing Digital"
                color="green"
                containerTextRight={false}
            />

            <SectionTextImage
                title1="Nuestro "
                title2="valor añadido"
                text={<Fragment>
                    Nuestra principal virtud es la <strong>personalización de cada uno de nuestros proyectos</strong>. IBX trabaja para cualquier sector y dimensión de empresa. Siempre nos adaptamos a las necesidades y presupuestos de nuestros clientes para así, poder crecer y caminar juntos.
                    </Fragment>}
                image={data.aboutImage3.childImageSharp.fluid}
                button="¡Contacta ahora!"
                buttonUrl="/contacto"
                imageAlt="Nuestro valor añadido - Agencia Marketing Digital"
                color="green"
                containerTextRight={true}
            />

            <SectionTextImage
                title1="¿Por qué confiar "
                title2="en IBX?"
                text={<Fragment>
                    Porqué somos la filosofía del marketing actual que hará despuntar tu negocio en el cambiante universo online. <br /><br />
                    ¿Te atreves a volar con nosotros?
                </Fragment>}
                button="¡Empieza ahora!"
                buttonUrl="/contacto"
                image={data.aboutImage4.childImageSharp.fluid}
                imageAlt="¿Por qué confiar en IBX? - Agencia Marketing Digital"
                color="green"
                containerTextRight={false}
            />

            <CallToAction
                color="green"
                title="¿Necesitas un plan de marketing digital para tu empresa?"
                text="Somos tu Agencia de Marketing Digital"
                button="Pide presupuesto ¡Gratis!"
                buttonUrl="/contacto"
            />



        </Layout>
    )

}
export default AboutPage
